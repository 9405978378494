import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  template: `
  <ng-container [formGroup]="formGroup">
    <div class="row">
            <div class="col-12">
              <!-- <label for="format" *ngIf="question?.showClientName=== true">{{ clientInfo.firstName }}, {{ question.question }}</label> -->
              <label for="format" *ngIf="question?.showClientName=== true">{{ question.question }}</label>

              <label for="format" *ngIf="!question?.showClientName">{{ question.question }}</label>
            </div>

            <div *ngFor="let option of question?.answerOptions" class="form-group pl-4 mb-1">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  [name]="question.controlName"
                  [id]="question.controlName"
                  value="{{ option }}"
                  [formControlName]="question.controlName"
                  (change)="onSelectStatus(option, question, formField.fields)"
                />
                <label for="year" class="form-check-label ml-1 px-1 text-capitalize">{{ option }} </label>
              </div>
            </div>
          </div>
          </ng-container>
  `,
  styles: [
  ]
})
export class CheckboxComponent implements OnInit {
  @Input() formGroup: any;
  @Input() question: any;
  @Input() formField: any;
  @Input() clientInfo: any;
  @Output() selectStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelectStatus(option: string, question: any, fields: any) {
    const params = {
      option,
      question,
      fields
    };

    this.selectStatus.emit(params);
  }

}
