import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ToastrService } from 'ngx-toastr';
import { Endpoints } from '../../../config';
import { ApiService } from '../../../services';
import { MustMatch } from '../../../all-modules/pages/invitation/mustwatch';
import * as MESSAGE from '../../../enum/info-messages.enum';
import * as ROUTES_TO from '../../../enum/routes.enum';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public inviForm: FormGroup;
  public resetPassword: FormGroup;
  public submitted: boolean;
  public processing: boolean = false;
  public userExist: boolean = false;
  public inviError: string = '';
  userId: number;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.params.id;
    this.initializeForm();
  }

  trimValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      if (value.startsWith(' ')) {
        return {
          trimError: { value: 'Password must not contain leading whitespaces' },
        };
      }

      if (value.endsWith(' ')) {
        return {
          trimError: { value: 'Password must not contain traling whitespaces' },
        };
      }

      return null;
    };
  }

  /**
   * @description Create invitation form group
   */
  initializeForm() {
    this.resetPassword = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(8), this.trimValidator()]],
        confirmPassword: ['', [Validators.required, this.trimValidator()]],
      },
      { validators: MustMatch('password', 'confirmPassword') },
    );
  }

  /**
   * @description Method is used to get the reset password controls.
   */
  public get getPasswordControls(): any {
    return this.resetPassword.controls;
  }

  /**
   *
   * @description Method is used to reset password.
   */
  reset() {
    this.spinner.show();
    this.processing = true;
    this.submitted = true;
    this.inviError = '';
    if (this.resetPassword.invalid) {
      this.processing = false;
      this.resetPassword.markAllAsTouched();
      this.spinner.hide();
      return false;
    }
    const params: any = {
      password: this.resetPassword.value.password.trim(),
      userId: this.userId,
    };
    this.apiService.put(`${Endpoints.verifyMemberPassword}`, params).subscribe(
      (resp: any) => {
        this.processing = false;
        this.submitted = false;
        this.spinner.hide();
        this.toastrService.success(MESSAGE.TOASTR.MESSAGE_RESETPASSWORD, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        this.router.navigate([ROUTES_TO.ROUTES_TO.AUTH], { state: { resp } });
      },
      error => {
        this.spinner.hide();
        this.processing = false;
        const inviError = error.error.message || MESSAGE.ERROR_MESSAGE.RESPONSE_MESSAGE;
        this.toastrService.error(inviError, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
      },
    );
  }
}
