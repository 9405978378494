import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Endpoints } from '../config';
import { Questionnaire } from '../shared/models/questionnaire';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ImmigrationService {
  childDef: string;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private apiService: ApiService) {}

  onSubmitInfo(params: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const matterId = params.matterId;
      const clientId = params.clientId;
      const questions = params.questions;
      const allowedSection = params.allowedSection;
      const formGroup = params.formGroup;

      const formSections = Object.keys(questions);
      formSections.forEach(section => {
        if (
          questions[section] &&
          questions[section].order &&
          questions[section].order !== '' &&
          allowedSection.includes(section)
        ) {
          if (questions[section]?.subSection) {
            questions[section].subSection.forEach(sub => {
              this.setAnswer(sub.fields, formGroup);
            });
          } else {
            this.setAnswer(questions[section].fields, formGroup);
          }
        }
      });

      const apiParams: Questionnaire = {
        matterId: matterId,
        clientId: clientId,
        questionnaire: questions,
        client_editable: false,
        editing: true,
        isPrincipal: params?.isPrincipal,
      };
      this.apiService.put(`${Endpoints.addAnswer}`, apiParams).subscribe(
        (data: any) => {
          this.toastr.success('Updated successfully');
          resolve(true);
        },
        err => {
          this.toastr.error(err, 'Alert');
          reject(true);
        },
      );
    });
  }

  setAnswer(fields: any, formGroup: FormGroup) {
    for (const [ind, field] of fields.entries()) {
      field.answer = formGroup.value[field?.controlName];
      if (field?.controls?.length > 0) {
        const formGroupControl = formGroup.get(field.formGroup)['controls'];
        if (formGroupControl && formGroupControl.length) {
          let controls = JSON.parse(JSON.stringify(field.controls));

          let final = controls.map((ch, ind) => {
            let d = ch.child.map(main => {
              return { ...main, answer: formGroupControl[ind].value[main?.controlName] };
            });
            ch.child = d;
            return ch;
          });
          field.controls = final;
        }
      }
    }
  }

  setMatterAddress(params: any, formType?: any) {
    // const field = params.field;
    // const mailingAddress = params.mailingAddress;
    // const formGroup = params.formGroup;

    const { field, mailingAddress, formGroup, userDetails } = params;

    if (userDetails) {
      field?.answer !== '' && field?.controlName === 'dayTimePhoneNumber'
        ? formGroup.get('dayTimePhoneNumber')?.setValue(field?.answer)
        : field?.controlName === 'dayTimePhoneNumber'
        ? formGroup.get('dayTimePhoneNumber')?.setValue(userDetails?.mobilePhone)
        : null;
      field?.answer !== '' && field?.controlName === 'otherPhoneNumber'
        ? formGroup.get('otherPhoneNumber')?.setValue(field?.answer)
        : field?.controlName === 'otherPhoneNumber'
        ? formGroup.get('otherPhoneNumber')?.setValue(userDetails?.homePhone)
        : null;
      field?.answer !== '' && field?.controlName === 'emailAddress'
        ? formGroup.get('emailAddress')?.setValue(field?.answer)
        : field?.controlName === 'emailAddress'
        ? formGroup.get('emailAddress')?.setValue(userDetails?.email)
        : null;
    }

    if (mailingAddress) {
      //setting the field answer for address, if not answer then brief-docket mailing address
      if (field.controlName) {
        field?.answer !== '' && field?.controlName === 'apartment'
          ? formGroup.get('apartment')?.setValue(field?.answer)
          : field?.controlName === 'apartment'
          ? formGroup.get('apartment')?.setValue(mailingAddress?.appartmentNumber)
          : null;

        if (formType?.frmType === 'Basis of claim') {
          if (field?.answer !== '' && field?.controlName === 'streetName') {
            formGroup.get('streetNumber')?.setValue(`${formType?.streetNo} ${field?.answer}`);
          } else if (field?.controlName === 'streetNumber') {
            formGroup.get('streetNumber')?.setValue(`${mailingAddress?.streetNumber} ${mailingAddress?.streetName}`);
          }
        } else {
          field?.answer !== '' && field?.controlName === 'streetNumber'
            ? formGroup.get('streetNumber')?.setValue(field?.answer)
            : field?.controlName === 'streetNumber'
            ? formGroup.get('streetNumber')?.setValue(mailingAddress?.streetNumber)
            : null;
          field?.answer !== '' && field?.controlName === 'streetName'
            ? formGroup.get('streetName')?.setValue(field?.answer)
            : field?.controlName === 'streetName'
            ? formGroup.get('streetName')?.setValue(mailingAddress?.streetName)
            : '';
        }

        if (formType?.frmType === 'Generic form') {
          if (field?.controlName === 'mailingAddressApartment' && field?.answer !== '') {
            formGroup.get('mailingAddressApartment')?.setValue(field?.answer);
          }
          // else if (field?.controlName === 'apartment') {
          //   formGroup.get('mailingAddressApartment')?.setValue(mailingAddress?.appartmentNumber);
          // }
        }

        field?.answer !== '' && field?.controlName === 'city'
          ? formGroup.get('city')?.setValue(field?.answer)
          : field?.controlName === 'city'
          ? formGroup.get('city')?.setValue(mailingAddress?.city)
          : '';
        field?.answer !== '' && field?.controlName === 'province'
          ? formGroup.get('province')?.setValue(field?.answer)
          : field?.controlName === 'province'
          ? formGroup.get('province')?.setValue(mailingAddress?.province)
          : '';
        field?.answer !== '' && field?.controlName === 'residenceCountry'
          ? formGroup.get('residenceCountry')?.setValue(field?.answer)
          : field?.controlName === 'residenceCountry'
          ? formGroup.get('residenceCountry')?.setValue(mailingAddress?.country)
          : '';
        field?.answer !== '' && field?.controlName === 'residenceAddPostalCode'
          ? formGroup.get('residenceAddPostalCode')?.setValue(field?.answer)
          : field?.controlName === 'residenceAddPostalCode'
          ? formGroup.get('residenceAddPostalCode')?.setValue(mailingAddress?.postalCode)
          : '';
      }
    }
  }

  addFormControls(questionObj) {
    const controls = this.addChildrens(questionObj);
    let con = questionObj.controls[0];
    questionObj.controls.push(con);
    this.getChildren(questionObj.formGroup).push(controls);
  }

  removeChild(question: any, index: number) {
    question.controls.splice(index, 1);
    this.getChildren(question.formGroup).removeAt(index);
  }

  addChildrens(field: any) {
    let arrControls;
    if (field?.controls?.length > 0 && field?.controls[0]?.child) {
      arrControls = field?.controls[0]?.child.map(arr => arr.controlName);
    } else {
      arrControls = field.controls.map(arr => arr.controlName);
    }
    const result = arrControls.reduce((obj, cur) => ({ ...obj, [cur]: [null] }), {});
    return this.formBuilder.group(result);
  }

  getChildren(controls: string, formGroup?: FormGroup): FormArray {
    return formGroup.get(controls) as FormArray;
  }
}
