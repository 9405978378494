import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BriefDocketService } from '../../../services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() userDetails;
  @Input() matterType;
  @Input() matterId;
  @Input() centerTitle: boolean = false;
  @Input() showBin: boolean = false;
  @Input() tabs: any;
  @Input() accessLevel: number;
  isEditable: boolean = false;
  matterDetails: any;
  url = '';

  constructor(private router: Router, private briefsService: BriefDocketService) {}

  ngOnInit() {
    this.briefsService.isEditable.subscribe(res => {
      this.isEditable = res;
    });
  }

  checkBin() {
    const matter = JSON.parse(localStorage.getItem('matterDetails'));
    if (matter && matter._id !== '') {
      this.router.navigate([`/matters/${matter._id}/bin`]);
    }
  }
}
