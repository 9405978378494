import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-signature-pad-modal',
  templateUrl: './signature-pad-modal.component.html',
  styleUrls: ['./signature-pad-modal.component.scss']
})
export class SignaturePadModalComponent implements OnInit {

  @ViewChild('addSignature', { static: true }) public addSignature: ModalDirective;
  @ViewChild('signature') public signaturePad: SignaturePadComponent;
  @Input() signatureFormControlName: string;
  @Input() formName: FormGroup;


  signaturePadOptions = {
    minWidth: 5,
    canvasWidth: 460,
    canvasHeight: 200
  };
  allowSignature = false;

  constructor() { }
  ngOnInit(): void {
  }

  openSignatureModal() {
    this.signaturePad.clear();
    this.addSignature.show();
  }

  onDrawStart(ev) {
    this.allowSignature = true;
  }

  clearSignature() {
    this.signaturePad.clear();
    this.allowSignature = false;
  }

  submitSignature() {
    const image = this.signaturePad.toDataURL();
    this.formName.get(this.signatureFormControlName).setValue(image);
    this.addSignature.hide();
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    }, 1000);
  }

  isInvalid(): boolean {
    return !this.signaturePad || this.signaturePad.isEmpty();
  }

}
