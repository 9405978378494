import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Endpoints } from '../../../../../../config';
import { ApiService, CommonService } from '../../../../../../services';
import * as MESSAGE from '../../../../../../enum/info-messages.enum';
import { environment } from '../../../../../../../environments/environment';
import * as moment from 'moment';
@Component({
  selector: 'app-trust-receipts',
  templateUrl: './trust-receipts.component.html',
  styleUrls: ['./trust-receipts.component.scss'],
})
export class TrustReceiptsComponent implements OnInit {
  windowWidth: number = window.innerWidth;
  @Input() matterSpecific: boolean;
  endPoints = Endpoints;
  matterDetails: any;
  receipts: any;
  totalBalance = 0;
  showPaymentModal = false;
  notFoundFlag = false;
  showEditModal = false;
  pageNo = 1;
  pageLimit = 10;
  totalPages: number;
  dataRange = '';
  modalTitle = 'Warning';
  transferType = 'credit';
  message: string;
  fileName: string;
  modalMessage = '';
  alertMesssage = 'Are you sure you want to delete this file?';
  confirm = 'confirm';
  cancel = 'cancel';
  selectedReceipt: any;
  type: string;
  showAlert = false;
  confirmationMessage: string;
  title: string;
  delSubs$: Subscription;
  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    try {
      const data: any = JSON.parse(localStorage.getItem('matterNavDetails'));
      this.matterDetails = data.matterDetails;
    } catch (err) {
      this.spinner.hide();
    }
    this.getTrustReceipts();
  }

  removeItem(receipt: any, pop: any) {
    pop?.hide();

    this.selectedReceipt = receipt;
    this.type = 'remove receipt';
    this.title = MESSAGE.ALERT_REMOVE_TASK.TITLE;
    this.confirm = MESSAGE.ALERT_REMOVE_TASK.CONFIRM;
    this.cancel = MESSAGE.ALERT_REMOVE_TASK.CANCEL;
    this.confirmationMessage = 'Do you want to remove this receipt?';
    this.message = 'Removing this receipt will deduct receipt amount from the trust balance.';
    this.showAlert = true;
  }
  async getTrustReceipts() {
    this.spinner.show();
    const data: any = await this.commonService
      .getTrustData(this.matterDetails?._id, this.matterSpecific, 'credit', {
        pageNumber: this.pageNo,
        pageSize: this.pageLimit,
      })
      .catch(err => {
        this.spinner.hide();
        this.notFoundFlag = true;
      });

    if (data?.records.length == 0) {
      this.notFoundFlag = true;
    }
    if (this.matterSpecific) {
      this.receipts = data?.records;
    } else {
      this.receipts = data?.records?.sort((a, b) => {
        let as: any = new Date(a.timestamps);
        let bs: any = new Date(b.timestamps);
        return bs - as;
      });
    }

    this.totalBalance = +data?.trustBalance?.$numberDecimal ? +data?.trustBalance?.$numberDecimal : 0;
    this.totalPages = data?.count;
    this.spinner.hide();
  }

  editReceipt(receipt: any, pop: any) {
    this.selectedReceipt = receipt;
    this.showEditModal = true;
    pop?.hide();
  }

  isClosed(ev: any) {
    this.showEditModal = !this.showEditModal;
    if (ev.state && ev.type === 'edit modal') {
      this.getTrustReceipts();
    }
  }

  removeReceipt() {
    this.apiService.post(this.endPoints.removeReceipt, this.selectedReceipt).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.toastr.success(response.message);
        this.getTrustReceipts();
      },
      err => {
        console.log(
          'TCL ->  ~ file: trust-receipts.component.ts ~ line 98 ~ TrustReceiptsComponent ~ this.apiService.deleteWithParams ~ err',
          err,
        );
        this.spinner.hide();
        this.toastr.error(err?.message);
      },
    );
  }

  isRemove(ev: any) {
    this.showAlert = !this.showAlert;
    if (ev.state && ev.type === 'remove receipt') {
      this.spinner.show();
      this.removeReceipt();
    }
  }

  pageChange(ev: number) {
    this.spinner.show();
    this.pageNo = ev;
    this.getTrustReceipts();
  }

  showModal() {
    const data: any = JSON.parse(localStorage.getItem('matterNavDetails'));
    this.matterDetails = data.matterDetails;
    this.showPaymentModal = true;
  }

  OnConfirm(ev: any) {
    if (ev?.isDone) {
      this.getTrustReceipts();
    }
    this.showPaymentModal = !this.showPaymentModal;
  }

  onPaymentModal(ev) {
    this.showPaymentModal = ev.modal;
  }
  getFile() {
    this.commonService.getFile({}, `${environment.base_url}trust/get-PDF`).subscribe(
      async (res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const fileName = `trust-disbursement-${Date.now()}.pdf`;
        const objectUrl = URL.createObjectURL(blob);

        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        this.spinner.hide();
      },
      err => {
        console.log('Error >', err);
      },
    );
  }
  async downloadPDF(dataRange, transferType: string) {
    this.spinner.show();
    let from = moment(dataRange[0]).format('YYYY-MM-DD');
    let to = moment(dataRange[1]).format('YYYY-MM-DD');
    this.dataRange = '';
    this.commonService
      .getFile({ from, to, transferType }, `${environment.base_url}${this.endPoints.downloadTrustData}`)
      .subscribe(
        async (res: any) => {
          this.getFile();
        },
        err => {
          this.spinner.hide();
        },
      );
  }
}
