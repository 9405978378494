import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService, DataService } from '.';
import { Endpoints } from '../config';
import { ERROR_MESSAGE, INFO_MESSAGES, TOASTR } from '../enum/info-messages.enum';
import { ROUTES_TO } from '../enum/routes.enum';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BriefDocketService {
  endpoints = Endpoints;
  initialLoad: boolean = false;
  loginErr: string = '';
  enableControls: Subject<boolean> = new Subject<boolean>();
  saveData: Subject<boolean> = new Subject<boolean>();
  cancel: Subject<boolean> = new Subject<boolean>();
  isEditable: Subject<boolean> = new Subject<boolean>();

  constructor(
    private spinner: NgxSpinnerService,
    private api: ApiService,
    private dataService: DataService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  getMatterDetails(matterId) {
    return this.api.get(`${this.endpoints.getMatter}/${matterId}`);
    //   .subscribe((res: any) => {
    //   if (res) {
    //     let matterDetails = res;
    //     matterDetails ? localStorage.setItem('matterDetails', JSON.stringify(matterDetails)) : '';
    //     let userDetails = matterDetails.user;
    //     this.dataService.matterData.next({ matterId: matterId, userDetails: userDetails, matterDetails: matterDetails })
    //     return res;
    //   }
    //   this.spinner.hide();
    // },
    //   (error) => {

    //     this.spinner.hide();
    //     let errMessage = error.error || 'Something went wrong';
    //   },
    // );
  }

  getLawyers() {
    this.api.get(this.endpoints.getLawyer).subscribe(
      (data: any) => {
        this.loginErr = '';
        if (data) {
          // if (this.getLawyersList || this.addMatter) {

          let lawyersDetail = [...data];
          lawyersDetail = lawyersDetail.map(lawyer => {
            lawyer['fullName'] = `${lawyer.firstName} ${lawyer.middleName} ${lawyer.lastName}`;
            lawyer['_id'] = lawyer._id;
            return lawyer;
          });
        }
      },
      error => {
        const loginErr = error.error || error.message || 'something went wrong';
        this.toastr.error(loginErr, 'Alert');
      },
    );
  }

  getClerks() {
    this.api.get(this.endpoints.getClerks).subscribe(
      (data: any) => {
        if (data) {
          let clerksDetail = data;
          let otherLawyers = [...clerksDetail];
          return otherLawyers;
        }
      },
      error => {
        const loginErr = error.error || error.message || 'something went wrong';
        this.toastr.error(loginErr, 'Alert');
      },
    );
  }

  updateMatterStatus(formData: any) {
    this.api.put(this.endpoints.matter, formData).subscribe(
      (res: any) => {
        if (res) {
          this.toastr.success(TOASTR.MESSAGE_MATER_CLOSED, INFO_MESSAGES.SUCCESS);
          this.router.navigate([ROUTES_TO.MATTERS]);
        }
      },
      error => {
        console.log('error', error);
        this.toastr.success(ERROR_MESSAGE.RESPONSE_MESSAGE, TOASTR.ERROR);
      },
    );
  }

  getAnswers(matter: any) {
    return this.api.getParams(`${Endpoints.getAnswer}`, { matterId: matter._id, clientId: matter.clientId });
  }
}
