import { INavData } from '@coreui/angular';

export const userNavItems: INavData[] = [
  {
    name: 'Home',
    url: '/home',
    icon: 'icon-home',
  },
  {
    class: 'border-bottom',
    divider: true,
  },
  {
    divider: true,
  },
  // {
  //     name: 'Admin',
  //     url: 'clientname',
  //     class: 'client-name',
  //     attributes: { disabled: true },
  //     // icon: 'icon-user',
  // },
  {
    name: 'Claim Steps',
    url: '/admin',
    icon: 'icon-docs',
  },
  {
    name: 'Law Firm Members',
    url: '/admin/law-firm-members',
    icon: 'icon-people',
  },
  {
    name: 'Archived Users',
    url: '/admin/archived-users',
    icon: 'icon-folder-alt',
  },
  {
    name: 'Clients',
    url: '/admin/clients',
    icon: 'icon-people',
  },
  {
    name: 'Archived Clients',
    url: '/admin/archived-clients',
    icon: 'icon-folder-alt',
  },
  {
    name: 'Precedent',
    url: '/admin/precedents',
    icon: 'icon-note',
  },
  {
    name: 'Document Titles',
    url: '/admin/document-titles',
    icon: 'icon-notebook',
  },
];
