export const Courts = [
  {
    name: 'Ontario Court of Justice'
  },
  {
    name: 'Superior Court of Justice'
  },
  {
    name: 'Superior Court of Justice Family Court Branch'
  }
];

export const FormFields = [
  {
    name: 'Full legal name:',
    formControlName: 'applicantFullLegalName',
    type: 'text',
  },
  {
    name: 'Address:',
    formControlName: 'fullAddress',
    type: 'text'
  },
  {
    name: 'Phone & fax:',
    formControlName: 'contactInfo',
    type: 'text'
  },
  {
    name: 'Email:',
    formControlName: 'applicantEmail',
    type: 'email'
  }
];

export const RespondentFormFields = [
  {
    name: 'Full legal name:',
    formControlName: 'respondentFullLegalName',
    type: 'text',
  },
  {
    name: 'Address:',
    formControlName: 'fullAddress',
    type: 'text'
  },
  {
    name: 'Phone & fax:',
    formControlName: 'contactInfo',
    type: 'text'
  },
  {
    name: 'Email:',
    formControlName: 'respondentEmail',
    type: 'email'
  }
];

export const RespondentLawyerFormFields = [
  {
    name: 'Name:',
    formControlName: 'respondentLawyerFullLegalName',
    type: 'text',
  },
  {
    name: 'Address:',
    formControlName: 'fullAddress',
    type: 'text'
  },
  {
    name: 'Phone & fax:',
    formControlName: 'contactInfo',
    type: 'text'
  },
  {
    name: 'Email:',
    formControlName: 'respondentLawyerEmail',
    type: 'email'
  }
];


export const AssigneeFormFields = [
  {
    name: 'Full legal name:',
    formControlName: 'assigneeFullLegalName',
    type: 'text',
  },
  {
    name: 'Address:',
    formControlName: 'fullAddress',
    type: 'text'
  },
  {
    name: 'Phone & fax:',
    formControlName: 'contactInfo',
    type: 'text'
  },
  {
    name: 'Email:',
    formControlName: 'assigneeEmail',
    type: 'email'
  }
];

export const AssigneeLawyerFormFields = [
  {
    name: 'Name:',
    formControlName: 'assigneeLawyerFullLegalName',
    type: 'text',
  },
  {
    name: 'Address:',
    formControlName: 'fullAddress',
    type: 'text'
  },
  {
    name: 'Phone & fax:',
    formControlName: 'contactInfo',
    type: 'text'
  },
  {
    name: 'Email:',
    formControlName: 'assigneeLawyerEmail',
    type: 'email'
  }
];


export const LawyerFormFields = [
  {
    name: 'Name:',
    formControlName: 'applicantLawyerFullLegalName',
    type: 'text',
  },
  {
    name: 'Address:',
    formControlName: 'fullAddress',
    type: 'text'
  },
  {
    name: 'Phone & fax:',
    formControlName: 'contactInfo',
    type: 'text'
  },
  {
    name: 'Email:',
    formControlName: 'applicantLawyerEmail',
    type: 'email'
  },
];

export const changesToBeMade = [
  {
    name: 'decision-making responsibility',
    value: 'decisionMaking',
    formControlName: 'decisionMaking'
  },
  {
    name: 'parenting time',
    value: 'parentingTime',
    formControlName: 'parentingTime'
  },
  {
    name: 'child support – table amount',
    value: 'childSupportTableAmount',
    formControlName: 'childSupportTableAmount'
  },
  {
    name: 'spousal support',
    value: 'spousalSupport',
    formControlName: 'spousalSupport'
  },
  {
    name: 'child support – special or extraordinary expenses',
    value: 'childSupportSpecialExpenses',
    formControlName: 'childSupportSpecialExpenses'
  },
  {
    name: 'contact',
    value: 'contact',
    formControlName: 'contact'
  },
  // {
  //   name: '(list type of expenses):',
  //   formControlName:'typeOfExpenses'
  // },
  // {
  //   name: 'other (give details):',
  //   formControlName: 'otherDetails'
  // }
];

export const RelationShipDates = [
  {
    labelName: 'Married on',
    formControlName: 'marriedOnDate',
    formControlCb: "marriedOnCB",
    value: 'marriedDate'
  },
  {
    labelName: 'Started living together on',
    formControlName: 'livingTogetherDate',
    formControlCb: 'livingTogetherCB',
    value: 'livingTogetherDate'
  },
  {
    labelName: 'Separated on',
    formControlName: 'separatedOnDate',
    formControlCb: "separatedOnCB",
    value: 'separatedDate'
  },
  {
    labelName: 'Never lived together',
    formControlCb: "neverLivedTogetherCB",
    value: 'neverLivedTogether'
  },

  {
    labelName: 'Still living together',
    formControlCb: "neverLivedTogetherCB",
    value: 'stillLivingTogether'
  },
];

export const Claims = [
  {
    name: 'Claims under the Divorce Act',
    list: [
      {
        no: '00',
        name: 'a divorce',
        formControlName: 'divorceCDA',
        type: "checkbox"
      },
      {
        no: '01',
        name: 'spousal support ',
        formControlName: "SupportForMeCDA",
        type: "checkbox"
      },
      {
        no: '02',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCDA',
        type: "checkbox"
      },
      {
        no: '03',
        name: 'support for child(ren) – other than table amount',
        formControlName: "SupportForChildrenCDA",
        type: "checkbox"
      },
      {
        no: '04',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCDA',
        type: "checkbox"
      },
      {
        no: '05',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCDA',
        type: "checkbox"
      },
    ]
  },
  {
    name: 'Claims under the Family Law Act or Children’s Law Reform Act',
    list: [

      {
        no: '10',
        name: 'spousal support',
        formControlName: "supportForMeCFLA",
        type: "checkbox"
      },
      {
        no: '11',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '12',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'childrenOthTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '13',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCFLA',
        type: "checkbox"
      },
      {
        no: '14',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCFLA',
        type: "checkbox"
      },
      {
        no: '15',
        name: 'restraining/non-harassment order',
        formControlName: 'nonHarassmentOrderCFLA',
        type: "checkbox"
      },
      {
        no: '16',
        name: 'indexing spousal support',
        formControlName: 'indexingSpousalSupportCFLA',
        type: "checkbox"
      },
      {
        no: '17',
        name: 'declaration of parentage',
        formControlName: 'parentageDeclarationCFLA',
        type: "checkbox"
      },
      {
        no: '18',
        name: 'guardianship over child’s property',
        formControlName: 'guardianshipChildPropertyCFLA',
        type: "checkbox"
      },


    ]
  },
  {
    name: 'Claims relating to property',
    list: [

      {
        no: '20',
        name: 'equalization of net family properties',
        formControlName: 'equalNetFamPropCRP',
        type: "checkbox"

      },
      {
        no: '21',
        name: 'exclusive possession of matrimonial home',
        formControlName: 'matrimonialHomeCRP',
        type: "checkbox"

      },
      {
        no: '22',
        name: 'exclusive possession of contents of matrimonial home',
        formControlName: 'matrimonialHomeContentCRP',
        type: "checkbox"

      },
      {
        no: '23',
        name: 'freezing assets',
        formControlName: 'freezingAssetsCRP',
        type: "checkbox"

      },
      {
        no: '24',
        name: 'sale of family property',
        formControlName: 'saleFamPropertyCRP',
        type: "checkbox"

      }]

  },
  {
    name: 'Other claims',
    list: [

      {
        no: '30',
        name: 'costs',
        formControlName: 'costsOC',
        type: "checkbox"
      },
      {
        no: '31',
        name: 'annulment of marriage',
        formControlName: 'annulmentMarriageOC',
        type: "checkbox"
      },
      {
        no: '32',
        name: 'prejudgment interest',
        formControlName: 'preJudgementInterestOC',
        type: "checkbox"
      },
      {
        no: '50',
        name: 'Other (Specify)',
        formControlName: 'otherSpecifyOC',
        type: "checkbox"
      }
    ]
  }
];


export const ApplicationGeneral = [
  {
    title: [{ text: 'Claims under the' }, { text: ' Divorce Act', style: 'font-italic' }],
    claimInstruction: '(Check boxes in this column only if you are asking for a divorce and your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [
      {
        no: '00',
        name: 'a divorce',
        formControlName: 'divorceCDA'
      },
      {
        no: '01',
        name: 'support for me',
        formControlName: 'SupportForMeCDA'

      },
      {
        no: '02',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCDA'
      },
      {
        no: '03',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'SupportForChildrenCDA'
      },
      {
        no: '04',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCDA'
      },
      {
        no: '05',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCDA'
      },
      {
        no: '06',
        name: [{
          text: 'contact with child(ren)',
        }, {
          text: '(this requires court leave)',
          style: 'font-italic'
        }],
        formControlName: 'contactChildrenCDA'
      },
    ]
  },
  {
    title: [{ text: 'Claims under the' }, { text: ' Family Law Act', style: 'font-italic' }, { text: ' or' }, { text: ' Children’s Law Reform Act', style: 'font-italic' }],
    list: [

      {
        no: '10',
        name: ' support for me',
        formControlName: 'supportForMeCFLA',
        type: "checkbox"
      },
      {
        no: '11',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '12',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'childrenOthTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '13',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCFLA',
        type: "checkbox"
      },
      {
        no: '14',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCFLA',
        type: "checkbox"
      },
      {
        no: '15',
        name: 'restraining/non-harassment order',
        formControlName: 'nonHarassmentOrderCFLA',
        type: "checkbox"
      },
      {
        no: '16',
        name: 'indexing spousal support',
        formControlName: 'indexingSpousalSupportCFLA',
        type: "checkbox"
      },
      {
        no: '17',
        name: 'declaration of parentage',
        formControlName: 'parentageDeclarationCFLA',
        type: "checkbox"
      },
      {
        no: '18',
        name: 'guardianship over child’s property',
        formControlName: 'guardianshipChildPropertyCFLA',
        type: "checkbox"
      },
      {
        no: '19',
        name: [{ text: 'contact with child(ren)' }, {
          text: '(this does not require court leave)', style:
            'font-italic'
        }],
        formControlName: 'contactWithChildrenCFLA',
        type: "checkbox"
      },
      {
        no: '20',
        name: 'wrongful removal to or retention of child(ren) in Ontario involving a country outside Canada under the Convention on the Civil Aspects of International Child Abduction',
        formControlName: 'retentionCFLA',
        type: "checkbox"
      },
      {
        no: '21',
        name: 'wrongful removal to or retention of child(ren) in Ontario involving a country outside Canada NOT under the Convention on the Civil Aspects of International Child Abduction',
        formControlName: 'retentionOtherCFLA',
        type: "checkbox"
      },

    ]
  },
  {
    title: 'Claims relating to property',
    claimInstruction: '(Check boxes in this column only if your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [

      {
        no: '22',
        name: 'equalization of net family properties',
        formControlName: 'equalNetFamPropCRP'
      },
      {
        no: '23',
        name: 'exclusive possession of matrimonial home',
        formControlName: 'matrimonialHomeCRP'
      },
      {
        no: '24',
        name: 'exclusive possession of contents of matrimonial home',
        formControlName: 'matrimonialHomeContentCRP'
      },
      {
        no: '25',
        name: 'freezing assets',
        formControlName: 'freezingAssetsCRP'
      },
      {
        no: '26',
        name: 'sale of family property',
        formControlName: 'saleFamPropertyCRP'
      }]

  },
  {
    list: [

      {
        no: '30',
        name: 'costs',
        formControlName: 'costsOC',
        type: "checkbox"
      },
      {
        no: '31',
        name: 'annulment of marriage',
        formControlName: 'annulmentMarriageOC',
        type: "checkbox"
      },
      {
        no: '32',
        name: 'prejudgment interest',
        formControlName: 'preJudgementInterestOC',
        type: "checkbox"
      },
      {
        no: '33',
        name: 'claims relating to a family arbitration',
        formControlName: 'FamArbitrationOC',
        type: "checkbox"
      }

    ]
  },
  {
    no: '50',
    name: [{ text: 'Other' }, { text: '(Specify)', style: 'font-italic' }],
    formControlName: 'otherSpecifyOC',
    inputControlName: 'otherSpecify',
    inputControlType: 'text',
    type: "checkbox",
  }
];

export const AnswerFormClaims = [
  {
    title: 'Claims under the Divorce Act',
    claimInstruction: '(Check boxes in this column only if you are asking for a divorce and your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [
      {
        no: '00',
        name: 'a divorce',
        formControlName: 'divorceCDA'
      },
      {
        no: '01',
        name: 'support for me',
        formControlName: 'SupportForMeCDA'

      },
      {
        no: '02',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCDA'
      },
      {
        no: '03',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'SupportForChildrenCDA'
      },
      {
        no: '04',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCDA'
      },
      {
        no: '05',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCDA'
      },
      {
        no: '06',
        name: 'contact with child(ren) (this requires court leave)',
        formControlName: 'contactChildrenCDA'
      },
    ]
  },
  {
    title: 'Claims relating to property',
    claimInstruction: '(Check boxes in this column only if your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [

      {
        no: '20',
        name: 'equalization of net family properties',
        formControlName: 'equalNetFamPropCRP'
      },
      {
        no: '21',
        name: 'exclusive possession of matrimonial home',
        formControlName: 'matrimonialHomeCRP'
      },
      {
        no: '22',
        name: 'exclusive possession of contents of matrimonial home',
        formControlName: 'matrimonialHomeContentCRP'
      },
      {
        no: '23',
        name: 'freezing assets',
        formControlName: 'freezingAssetsCRP'
      },
      {
        no: '24',
        name: 'sale of family property',
        formControlName: 'saleFamPropertyCRP'
      }]

  },
  {
    title: 'Claims relating to child protection',
    list: [
      {
        no: '40',
        name: 'access',
        formControlName: 'accessCCP'
      },
      {
        no: '41',
        name: ' lesser protection order',
        formControlName: 'lesserProtectionCCP'
      },
      {
        no: '42',
        name: 'return of child(ren) to my care',
        formControlName: 'returnToCareCCP'
      },
      {
        no: '43',
        name: ' place child(ren) into care of (name)',
        formControlName: 'placeChildrenIntoCareOfCCP',
        inputControlName: 'careTakerName',
        inputControlType: 'text',
      },
      {
        no: '44',
        name: 'interim society care and custody for months',
        formControlName: 'societyCareCustodyForMonthsCCP',
        inputControlName: 'custodyForMonths',
        inputControlType: 'number',

      },
      {
        no: '45',
        name: 'society supervision of my child(ren) for months',
        formControlName: 'societySupervisionForMonthsCCP',
        inputControlName: 'supervisionForMonths',
        inputControlType: 'number',

      },
    ]
  },
  {
    title: 'Claims under the Family Law Act or Children’s Law Reform Act',
    list: [

      {
        no: '10',
        name: ' support for me',
        formControlName: 'supportForMeCFLA',
        type: "checkbox"
      },
      {
        no: '11',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '12',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'childrenOthTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '13',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCFLA',
        type: "checkbox"
      },
      {
        no: '14',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCFLA',
        type: "checkbox"
      },
      {
        no: '15',
        name: 'restraining/non-harassment order',
        formControlName: 'nonHarassmentOrderCFLA',
        type: "checkbox"
      },
      {
        no: '16',
        name: 'indexing spousal support',
        formControlName: 'indexingSpousalSupportCFLA',
        type: "checkbox"
      },
      {
        no: '17',
        name: 'declaration of parentage',
        formControlName: 'parentageDeclarationCFLA',
        type: "checkbox"
      },
      {
        no: '18',
        name: 'guardianship over child’s property',
        formControlName: 'guardianshipChildPropertyCFLA',
        type: "checkbox"
      },
      {
        no: '19',
        name: ' contact with child(ren) (this does not require court leave)',
        formControlName: 'contactWithChildrenCFLA',
        type: "checkbox"
      },

    ]
  },

  {
    title: 'Other claims',
    list: [

      {
        no: '30',
        name: 'costs',
        formControlName: 'costsOC',
        type: "checkbox"
      },
      {
        no: '31',
        name: 'annulment of marriage',
        formControlName: 'annulmentMarriageOC',
        type: "checkbox"
      },
      {
        no: '32',
        name: 'prejudgment interest',
        formControlName: 'preJudgementInterestOC',
        type: "checkbox"
      },
      {
        no: '33',
        name: 'claims relating to a family arbitration',
        formControlName: 'FamArbitrationOC',
        type: "checkbox"
      }

    ]
  },
  {
    no: '50',
    name: 'Other (Specify)',
    formControlName: 'otherSpecifyOC',
    inputControlName: 'otherSpecify',
    inputControlType: 'text',
    type: "checkbox",
  }
];


export const AnswerFormClaimsUpdated = [
  {
    title: [{ text: 'Claims under the' }, { text: ' Divorce Act', style: 'font-italic' }],
    claimInstruction: '(Check boxes in this column only if you are asking for a divorce and your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [
      {
        no: '00',
        name: 'a divorce',
        formControlName: 'divorceCDA'
      },
      {
        no: '01',
        name: 'support for me',
        formControlName: 'SupportForMeCDA'

      },
      {
        no: '02',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCDA'
      },
      {
        no: '03',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'SupportForChildrenCDA'
      },
      {
        no: '04',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCDA'
      },
      {
        no: '05',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCDA'
      },
      {
        no: '06',
        name: [{
          text: 'contact with child(ren)',
        }, {
          text: '(this requires court leave)',
          style: 'font-italic'
        }],
        formControlName: 'contactChildrenCDA'
      },
    ]
  },
  {
    title: 'Claims relating to property',
    claimInstruction: '(Check boxes in this column only if your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [

      {
        no: '22',
        name: 'equalization of net family properties',
        formControlName: 'equalNetFamPropCRP'
      },
      {
        no: '23',
        name: 'exclusive possession of matrimonial home',
        formControlName: 'matrimonialHomeCRP'
      },
      {
        no: '24',
        name: 'exclusive possession of contents of matrimonial home',
        formControlName: 'matrimonialHomeContentCRP'
      },
      {
        no: '25',
        name: 'freezing assets',
        formControlName: 'freezingAssetsCRP'
      },
      {
        no: '26',
        name: 'sale of family property',
        formControlName: 'saleFamPropertyCRP'
      }]

  },
  {
    title: 'Claims relating to child protection',
    list: [
      {
        no: '40',
        name: 'access',
        formControlName: 'accessCCP'
      },
      {
        no: '41',
        name: ' lesser protection order',
        formControlName: 'lesserProtectionCCP'
      },
      {
        no: '42',
        name: 'return of child(ren) to my care',
        formControlName: 'returnToCareCCP'
      },
      {
        no: '43',
        name: [{ text: ' place child(ren) into care of ' }, { text: '(name)', style: 'font-italic' }],
        formControlName: 'placeChildrenIntoCareOfCCP',
        inputControlName: 'careTakerName',
        inputControlType: 'text',
      },
      {
        no: '44',
        name: 'interim society care and custody for ',
        formControlName: 'societyCareCustodyForMonthsCCP',
        controlName: 'custodyForMonths',
        inputControlType: 'number',

      },
      {
        no: '45',
        name: 'society supervision of my child(ren) for ',
        formControlName: 'societySupervisionForMonthsCCP',
        controlName: 'supervisionForMonths',
        inputControlType: 'number',

      },
    ]
  },
  {
    title: [{ text: 'Claims under the' }, { text: ' Family Law Act', style: 'font-italic' }, { text: ' or' }, { text: ' Children’s Law Reform Act', style: 'font-italic' }],
    list: [

      {
        no: '10',
        name: ' support for me',
        formControlName: 'supportForMeCFLA',
        type: "checkbox"
      },
      {
        no: '11',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '12',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'childrenOthTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '13',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCFLA',
        type: "checkbox"
      },
      {
        no: '14',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCFLA',
        type: "checkbox"
      },
      {
        no: '15',
        name: 'restraining/non-harassment order',
        formControlName: 'nonHarassmentOrderCFLA',
        type: "checkbox"
      },
      {
        no: '16',
        name: 'indexing spousal support',
        formControlName: 'indexingSpousalSupportCFLA',
        type: "checkbox"
      },
      {
        no: '17',
        name: 'declaration of parentage',
        formControlName: 'parentageDeclarationCFLA',
        type: "checkbox"
      },
      {
        no: '18',
        name: 'guardianship over child’s property',
        formControlName: 'guardianshipChildPropertyCFLA',
        type: "checkbox"
      },
      {
        no: '19',
        name: [{ text: 'contact with child(ren)' }, {
          text: '(this does not require court leave)', style:
            'font-italic'
        }],
        formControlName: 'contactWithChildrenCFLA',
        type: "checkbox"
      },
      {
        no: '20',
        name: 'wrongful removal to or retention of child(ren) in Ontario involving a country outside Canada under the Convention on the Civil Aspects of International Child Abduction',
        formControlName: 'retention',
        type: "checkbox"
      },
      {
        no: '21',
        name: `wrongful removal to or retention of child(ren) in Ontario involving a country outside Canada NOT under the Convention on the Civil Aspects of International Child Abduction`,
        formControlName: 'notRetention',
        type: "checkbox"
      },

    ]
  },

  {
    title: 'Other claims',
    list: [

      {
        no: '30',
        name: 'costs',
        formControlName: 'costsOC',
        type: "checkbox"
      },
      {
        no: '31',
        name: 'annulment of marriage',
        formControlName: 'annulmentMarriageOC',
        type: "checkbox"
      },
      {
        no: '32',
        name: 'prejudgment interest',
        formControlName: 'preJudgementInterestOC',
        type: "checkbox"
      },
      {
        no: '33',
        name: 'claims relating to a family arbitration',
        formControlName: 'FamArbitrationOC',
        type: "checkbox"
      }

    ]
  },
  {
    no: '50',
    name: [{ text: 'Other' }, { text: '(Specify)', style: 'font-italic' }],
    formControlName: 'otherSpecifyOC',
    inputControlName: 'otherSpecify',
    inputControlType: 'text',
    type: "checkbox",
  }
];



export const CourtMadeOrderOnIssues = [
  {
    label: 'findings of fact set out in Part 1 above',
    formControlName: 'part1FindingFact'
  },
  {
    label: 'payment order',
    formControlName: 'paymentOrder'
  },
  {
    label: 'temporary care and custody',
    formControlName: 'temporaryCareCustody'
  },
  {
    label: 'access',
    formControlName: 'access'
  },
  {
    label: 'finding that child is in need of protection',
    formControlName: 'needOfProtection'
  },

];



// export const  {

//   list: [
//    {
//      label: 'placing the child(ren) with (name of person)',
//      formControlName: 'placeChildWithNameOfPerson',
//    },
//    {
//      label: 'for',
//      formControlName:'monthsUnderSupervision'
//    },
//    {
//      label: 'months under supervision.'
//    },
//  ],
// },
// {

//  list: [
//    {
//      label: 'Interim society care for',
//      formControlName: 'monthsInterimSocietyCare',
//    },
//    {
//      label: 'months',
//    },
//  ],
// },
// {
//  name: 'extended society care with access',
//  formControlName: 'extendedSocietyCareWithAccess'
// },
// {
//  name: 'Other (Specify)',
//  formControlName: 'otherSpecify'
// },
// {
//  name: 'extended society care without access',
//  formControlName: 'extendedSocietyCareWithoutAccess'

// }

export const ReplyByClaims = [
  {
    title: 'Under the Divorce Act',
    list: [
      {
        no: '00',
        name: 'a divorce',
        formControlName: 'divorceCDA'
      },
      {
        no: '01',
        name: 'support for me',
        formControlName: 'SupportForMeCDA'

      },
      {
        no: '02',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCDA'
      },
      {
        no: '03',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'SupportForChildrenCDA'
      },
      {
        no: '04',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCDA'
      },
      {
        no: '05',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCDA'
      },
      {
        no: '06',
        name: 'contact with child(ren) (this requires court leave)',
        formControlName: 'contactChildrenCDA'
      },
    ]
  },

  {
    title: `Family Law Act or Children's Law Reform Act`,
    list: [

      {
        no: '10',
        name: ' support for me',
        formControlName: 'supportForMeCFLA',
        type: "checkbox"
      },
      {
        no: '11',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '12',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'childrenOthTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '13',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCFLA',
        type: "checkbox"
      },
      {
        no: '14',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCFLA',
        type: "checkbox"
      },
      {
        no: '15',
        name: 'restraining/non-harassment order',
        formControlName: 'nonHarassmentOrderCFLA',
        type: "checkbox"
      },
      {
        no: '16',
        name: 'indexing spousal support',
        formControlName: 'indexingSpousalSupportCFLA',
        type: "checkbox"
      },
      {
        no: '17',
        name: 'declaration of parentage',
        formControlName: 'parentageDeclarationCFLA',
        type: "checkbox"
      },
      {
        no: '18',
        name: 'guardianship over child’s property',
        formControlName: 'guardianshipChildPropertyCFLA',
        type: "checkbox"
      },
      {
        no: '19',
        name: 'contact with child(ren) (this does not require court leave)',
        formControlName: 'contactWithChildrenCFLA',
        type: "checkbox"
      },
      {
        no: '20',
        name: 'wrongful removal to or retention of child(ren) in Ontario involving a country outside Canada under the Convention on the Civil Aspects of International Child Abduction',
        formControlName: 'retentionCFLA',
        type: "checkbox"
      },
      {
        no: '21',
        name: 'wrongful removal to or retention of child(ren) in Ontario involving a country outside Canada NOT under the Convention on the Civil Aspects of International Child Abduction',
        formControlName: 'retentionOtherCFLA',
        type: "checkbox"
      },

    ]
  },

  {
    title: 'Claims relating to property',
    claimInstruction: '(Check boxes in this column only if your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [

      {
        no: '22',
        name: 'equalization of net family properties',
        formControlName: 'equalNetFamPropCRP'
      },
      {
        no: '23',
        name: 'exclusive possession of matrimonial home',
        formControlName: 'matrimonialHomeCRP'
      },
      {
        no: '24',
        name: 'exclusive possession of contents of matrimonial home',
        formControlName: 'matrimonialHomeContentCRP'
      },
      {
        no: '25',
        name: 'freezing assets',
        formControlName: 'freezingAssetsCRP'
      },
      {
        no: '26',
        name: 'sale of family property',
        formControlName: 'saleFamPropertyCRP'
      }]

  },

  {
    title: 'Other claims',
    list: [

      {
        no: '30',
        name: 'costs',
        formControlName: 'costsOC',
        type: "checkbox"
      },
      {
        no: '31',
        name: 'annulment of marriage',
        formControlName: 'annulmentMarriageOC',
        type: "checkbox"
      },
      {
        no: '32',
        name: 'prejudgment interest',
        formControlName: 'preJudgementInterestOC',
        type: "checkbox"
      },
      {
        no: '33',
        name: 'claims relating to a family arbitration',
        formControlName: 'FamArbitrationOC',
        type: "checkbox"
      }

    ]
  },

  {
    title: 'Claims relating to child protection',
    list: [
      {
        no: '40',
        name: 'access',
        formControlName: 'accessCCP'
      },
      {
        no: '41',
        name: ' lesser protection order',
        formControlName: 'lesserProtectionCCP'
      },
      {
        no: '42',
        name: 'return of child(ren) to my care',
        formControlName: 'returnToCareCCP'
      },
      {
        no: '43',
        name: ' place child(ren) into care of (name)',
        formControlName: 'placeChildrenIntoCareOfCCP',
      },
      {
        no: '44',
        name: 'interim society care and custody for ',
        formControlName: 'societyCareCustodyForMonthsCCP',
        inputControlName: 'custodyForMonths',
        inputControlType: 'number',

      },
      {
        no: '45',
        name: 'society supervision of my child(ren) for ',
        formControlName: 'societySupervisionForMonthsCCP',
        inputControlName: 'supervisionForMonths',
        inputControlType: 'number',

      },
    ]
  },


  {
    list: [{
      formControlName: 'otherSpecifyOC',
      name: 'other ',
      no: '50',
      textareaControlName: 'otherSpecify',
    }
    ],
    inputControlType: 'text',
    type: "checkbox",
  }
];

export const ReplyByClaimsClient = [
  {
    title: 'Under the Divorce Act',
    list: [
      {
        no: '00',
        name: 'a divorce',
        formControlName: 'divorceCDAclient'
      },
      {
        no: '01',
        name: 'support for me',
        formControlName: 'SupportForMeCDAclient'

      },
      {
        no: '02',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCDAclient'
      },
      {
        no: '03',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'SupportForChildrenclient'
      },
      {
        no: '04',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCDAclient'
      },
      {
        no: '05',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCDAclient'
      },
      {
        no: '06',
        name: 'contact with child(ren) (this requires court leave)',
        formControlName: 'contactChildrenCDAclient'
      },
    ]
  },

  {
    title: `Family Law Act or Children's Law Reform Act`,
    list: [

      {
        no: '10',
        name: ' support for me',
        formControlName: 'supportForMeCFLAclient',
        type: "checkbox"
      },
      {
        no: '11',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCFLAclient',
        type: "checkbox"
      },
      {
        no: '12',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'childrenOthTableAmtCFLAclient',
        type: "checkbox"
      },
      {
        no: '13',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCFLAclient',
        type: "checkbox"
      },
      {
        no: '14',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCFLAclient',
        type: "checkbox"
      },
      {
        no: '15',
        name: 'restraining/non-harassment order',
        formControlName: 'nonHarassmentOrderCFLAclient',
        type: "checkbox"
      },
      {
        no: '16',
        name: 'indexing spousal support',
        formControlName: 'indexingSpousalSupportCFLAclient',
        type: "checkbox"
      },
      {
        no: '17',
        name: 'declaration of parentage',
        formControlName: 'parentageDeclarationCFLAclient',
        type: "checkbox"
      },
      {
        no: '18',
        name: 'guardianship over child’s property',
        formControlName: 'guardianshipChildPropertyCFLAclient',
        type: "checkbox"
      },
      {
        no: '19',
        name: ' contact with child(ren) (this does not require court leave)',
        formControlName: 'contactWithChildrenCFLAclient',
        type: "checkbox"
      },
      {
        no: '20',
        name: 'wrongful removal to or retention of child(ren) in Ontario involving a country outside Canada under the Convention on the Civil Aspects of International Child Abduction',
        formControlName: 'retentionCFLAclient',
        type: "checkbox"
      },
      {
        no: '21',
        name: 'wrongful removal to or retention of child(ren) in Ontario involving a country outside Canada NOT under the Convention on the Civil Aspects of International Child Abduction',
        formControlName: 'retentionOtherCFLAclient',
        type: "checkbox"
      },

    ]
  },

  {
    title: 'Claims relating to property',
    claimInstruction: '(Check boxes in this column only if your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [

      {
        no: '22',
        name: 'equalization of net family properties',
        formControlName: 'equalNetFamPropCRPclient'
      },
      {
        no: '23',
        name: 'exclusive possession of matrimonial home',
        formControlName: 'matrimonialHomeCRPclient'
      },
      {
        no: '24',
        name: 'exclusive possession of contents of matrimonial home',
        formControlName: 'matrimonialHomeContentCRPclient'
      },
      {
        no: '25',
        name: 'freezing assets',
        formControlName: 'freezingAssetsCRPclient'
      },
      {
        no: '26',
        name: 'sale of family property',
        formControlName: 'saleFamPropertyCRPclient'
      }]

  },

  {
    title: 'Other claims',
    list: [

      {
        no: '30',
        name: 'costs',
        formControlName: 'costsOCclient',
        type: "checkbox"
      },
      {
        no: '31',
        name: 'annulment of marriage',
        formControlName: 'annulmentMarriageOCclient',
        type: "checkbox"
      },
      {
        no: '32',
        name: 'prejudgment interest',
        formControlName: 'preJudgementInterestOCclient',
        type: "checkbox"
      },
      {
        no: '33',
        name: 'claims relating to a family arbitration',
        formControlName: 'FamArbitrationOCclient',
        type: "checkbox"
      }

    ]
  },

  {
    title: 'Claims relating to child protection',
    list: [
      {
        no: '40',
        name: 'access',
        formControlName: 'accessCCPclient'
      },
      {
        no: '41',
        name: ' lesser protection order',
        formControlName: 'lesserProtectionCCPclient'
      },
      {
        no: '42',
        name: 'return of child(ren) to my care',
        formControlName: 'returnToCareCCPclient'
      },
      {
        no: '43',
        name: ' place child(ren) into care of (name)',
        formControlName: 'placeChildrenIntoCareOfCCPclient',
      },
      {
        no: '44',
        name: 'interim society care and custody for ',
        formControlName: 'societyCareCustodyForMonthsCCPclient',
        inputControlName: 'custodyForMonthsClient',
        inputControlType: 'number',

      },
      {
        no: '45',
        name: 'society supervision of my child(ren) for ',
        formControlName: 'societySupervisionForMonthsCCPclient',
        inputControlName: 'supervisionForMonthsClient',
        inputControlType: 'number',

      },
    ]
  },


  {
    list: [{
      formControlName: 'otherSpecifyOCclient',
      name: 'other ',
      no: '50',
      textareaControlName: 'otherSpecifyClient',
    }
    ],
    inputControlType: 'text',
    type: "checkbox",
  }
];

export const ApplicationDivorceUpdated = [
  {
    title: [{ text: 'Claims under the' }, { text: ' Divorce Act', style: 'font-italic' }],
    claimInstruction: '(Check boxes in this column only if you are asking for a divorce and your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [
      {
        no: '00',
        name: 'a divorce',
        formControlName: 'divorceCDA'
      },
      {
        no: '01',
        name: 'spousal support',
        formControlName: 'spousalSupportCDA'

      },
      {
        no: '02',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCDA'
      },
      {
        no: '03',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'SupportForChildrenCDA'
      },
      {
        no: '04',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCDA'
      },
      {
        no: '05',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCDA'
      },

    ]
  },
  {
    title: [{ text: 'Claims under the' }, { text: ' Family Law Act', style: 'font-italic' }, { text: ' or' }, { text: ' Children’s Law Reform Act', style: 'font-italic' }],
    list: [

      {
        no: '10',
        name: ' spousal support',
        formControlName: 'spousalSupportCFLA',
        type: "checkbox"
      },
      {
        no: '11',
        name: 'support for child(ren) – table amount',
        formControlName: 'childrenTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '12',
        name: 'support for child(ren) – other than table amount',
        formControlName: 'childrenOthTableAmtCFLA',
        type: "checkbox"
      },
      {
        no: '13',
        name: 'decision-making responsibility for child(ren)',
        formControlName: 'decisionMakingRespCFLA',
        type: "checkbox"
      },
      {
        no: '14',
        name: 'parenting time with child(ren)',
        formControlName: 'parentingTimeCFLA',
        type: "checkbox"
      },
      {
        no: '15',
        name: 'restraining/non-harassment order',
        formControlName: 'nonHarassmentOrderCFLA',
        type: "checkbox"
      },
      {
        no: '16',
        name: 'indexing spousal support',
        formControlName: 'indexingSpousalSupportCFLA',
        type: "checkbox"
      },
      {
        no: '17',
        name: 'declaration of parentage',
        formControlName: 'parentageDeclarationCFLA',
        type: "checkbox"
      },
      {
        no: '18',
        name: 'guardianship over child’s property',
        formControlName: 'guardianshipChildPropertyCFLA',
        type: "checkbox"
      },

    ]
  },
  {
    title: 'Claims relating to property',
    claimInstruction: '(Check boxes in this column only if your case is in the Superior Court of Justice or Family Court of the Superior Court of Justice.)',
    list: [

      {
        no: '20',
        name: 'equalization of net family properties',
        formControlName: 'equalNetFamPropCRP'
      },
      {
        no: '21',
        name: 'exclusive possession of matrimonial home',
        formControlName: 'matrimonialHomeCRP'
      },
      {
        no: '22',
        name: 'exclusive possession of contents of matrimonial home',
        formControlName: 'matrimonialHomeContentCRP'
      },
      {
        no: '23',
        name: 'freezing assets',
        formControlName: 'freezingAssetsCRP'
      },
      {
        no: '24',
        name: 'sale of family property',
        formControlName: 'saleFamPropertyCRP'
      }],

    otherTitle: 'Other Claims',
    otherList: [

      {
        no: '30',
        name: 'costs',
        formControlName: 'costsOC',
        type: "checkbox"
      },
      {
        no: '31',
        name: 'annulment of marriage',
        formControlName: 'annulmentMarriageOC',
        type: "checkbox"
      },
      {
        no: '32',
        name: 'prejudgment interest',
        formControlName: 'preJudgementInterestOC',
        type: "checkbox"
      },
      {
        no: '50',
        name: [{ text: 'Other' }, { text: '(Specify)', style: 'font-italic' }],
        formControlName: 'otherSpecifyOC',
        inputControlName: 'otherSpecify',
        inputControlType: 'text',
        type: "checkbox",
      }

    ]


  },
]

export const RespondentLawyerFormFieldsForm13 = [
  {
    name: 'Full legal name:',
    formControlName: 'respondentLawyerFullLegalName',
    type: 'text',
  },
  {
    name: 'Address:',
    formControlName: 'fullAddress',
    type: 'text'
  },
  {
    name: 'Phone & fax:',
    formControlName: 'contactInfo',
    type: 'text'
  },
  {
    name: 'Email:',
    formControlName: 'respondentLawyerEmail',
    type: 'email'
  }
];

export const LawyerFormFieldsForm13 = [
  {
    name: 'Full legal name:',
    formControlName: 'applicantLawyerFullLegalName',
    type: 'text',
  },
  {
    name: 'Address:',
    formControlName: 'fullAddress',
    type: 'text'
  },
  {
    name: 'Phone & fax:',
    formControlName: 'contactInfo',
    type: 'text'
  },
  {
    name: 'Email:',
    formControlName: 'applicantLawyerEmail',
    type: 'email'
  },
];