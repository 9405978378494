import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import {
  DataService,
  ApiService,
  CommonService,
  AlertService,
  AuthService,
  BriefDocketService,
} from '../../../services';

import { Endpoints } from '../../../config';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as MESSAGE from '../../../enum/info-messages.enum';
import { LOCAL_STORAGE } from '../../../enum/local-storage.enum';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss'],
})
export class AddTaskComponent implements OnInit, OnChanges {
  @ViewChild('taskModal') public taskModal: ModalDirective;

  @Input() taskInfo: any;
  @Input() clerkList: any[];
  @Input() eventDate;

  @Output() confirm = new EventEmitter();

  addInvitees = [];
  taskEdit = false;
  removedInvitees = [];
  addTaskForm: FormGroup;
  taskClose = false;
  isDocketSubmitted = false;
  endpoints = Endpoints;
  minuteErr: string;
  docketStartDate;
  title = 'Warning';
  alertMessage = 'This was the last task for this matter. What would you like to do?';
  confirmButton = 'Create new task';
  cancelButton = 'Close Matter';
  showModal = false;
  isSubmittedAddTaskForm = false;
  isFutureDateAllowed = true;
  caseTask: any;
  minDate = new Date();
  colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#2196f3', '#ffc107', '#795548', '#009688'];

  constructor(
    private dataService: DataService,
    private api: ApiService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private alert: AlertService,
    private auth: AuthService,
    private router: Router,
    private briefDocketService: BriefDocketService,
  ) {}

  ngOnInit(): void {
    this.isFutureDateAllowed = this.taskInfo?.claimDataToSend?.futureDateAllowed;
    this.spinner.show();
    this.init();
  }

  init() {
    this.initializeForm();
    try {
      const currentUser: any = JSON.parse(localStorage.getItem('userDetails'));
      if (currentUser && currentUser?._id) {
        this.addTaskForm.get('assignedTo').setValue(currentUser._id);

        // if (this.taskInfo && this.taskInfo?.taskLawyers) {
        //   const lawyer = this.taskInfo?.taskLawyers.filter(lawyer => lawyer?._id === currentUser?._id)[0];
        //   this.addTaskForm.get('assignedTo').setValue(lawyer._id);
        // } else {
        //   this.addTaskForm.get('assignedTo').setValue(currentUser._id);
        // }
      }
      if (this.showModal) {
        this.taskModal.show();
      }
    } catch (err) {}
    this.checkCaseTask();
  }

  checkCaseTask() {
    this.addInvitees = [];
    const apiEndPoint = this.api.getParams(`${this.endpoints.getCaseTask}`, {
      title: this.taskInfo?.claimDataToSend?.claimTitle,
      matterId: this.taskInfo?.matterId,
    });
    apiEndPoint.subscribe(
      res => {
        this.caseTask = res;
        this.taskEdit = true;
        this.addTaskForm.patchValue({
          taskTitle: this.caseTask.taskTitle,
          taskDescription: this.caseTask.taskDescription,
          taskDate: this.caseTask.taskDate,
          taskStart: this.caseTask.taskStart,
          taskEnd: this.caseTask.taskEnd,
          assignedTo: this.caseTask.assignedTo,
          taskColor: this.caseTask.taskColor,
          email: this.caseTask.invitees,
        });
        this.addInvitees = this.caseTask?.invitees.length > 0 ? this.caseTask?.invitees : [];
        this.addTaskForm.get('taskTitle').disable();
        // this.addTaskForm.updateValueAndValidity();
        this.spinner.hide();
      },
      err => {
        this.taskEdit = false;

        this.addTaskForm
          .get('taskTitle')
          .setValue(this.taskInfo?.type === 'task' ? 'Status review.' : this.taskInfo?.claimDataToSend?.claimTitle);
        this.taskInfo?.claimDataToSend?.claimTitle ? this.addTaskForm.get('taskTitle').disable() : null;
        this.spinner.hide();
      },
    );

    if (this.taskInfo.type === 'task') {
      const date = new Date();
      date.setDate(date.getDate() + 1);

      if (this.taskInfo.type === 'task') {
        this.addTaskForm.patchValue({
          taskTitle: 'Status review.',
          taskDescription: 'File to be reviewed.',
          taskDate: date,
          taskStart: '12:00',
          taskEnd: '12:25',
          assignedTo: this.taskInfo.creatorId,
          taskColor: '',
          email: '',
        });
        this.addTaskForm.get('taskTitle').setValue('Status review');
      }
    }
  }

  ngOnChanges() {
    setTimeout(() => {
      this.taskModal.show();
    }, 100);
  }

  get getControls() {
    return this.addTaskForm.controls;
  }

  /**
   * @description Method is used to create form group
   */
  initializeForm() {
    this.addTaskForm = this.fb.group({
      taskTitle: ['', [Validators.required]],
      taskDescription: [''],
      taskDate: ['', [Validators.required]],
      taskStart: ['', [Validators.required]],
      taskEnd: [''],
      assignedTo: ['', Validators.required],
      taskLocation: [''],
      email: [''],
      mapsUrl: [''],
      taskColor: [null],
    });
    if (!this.taskInfo?.claimDataToSend) {
      this.addTaskForm.get('taskDate').patchValue(moment(this.eventDate).format('YYYY-MM-DD'));
    }
  }

  addTask(updateClaimList: boolean) {
    let formdata: any;
    try {
      this.spinner.show();
      const taskDesc = this.addTaskForm.controls['taskDescription'].value;

      const taskDate = moment(this.addTaskForm.controls['taskDate'].value).format('YYYY-MM-DD');
      const taskStart = this.addTaskForm.controls['taskStart'].value;
      const eventStartDateTime = new Date(`${taskDate}T${taskStart}`);
      const taskEnd = this.addTaskForm.controls['taskEnd'].value;
      const eventEndDateTime = new Date(`${taskDate}T${taskEnd}`);

      formdata = {
        matterId: this.taskInfo.matterId,
        clientId: this.taskInfo.clientId,
        subscriberId: this.taskInfo.subscriberId,
        taskTitle:
          this.addTaskForm.value.taskTitle !== undefined
            ? this.addTaskForm.value.taskTitle
            : this.taskInfo.claimDataToSend.claimTitle,
        taskDate: moment(this.addTaskForm.value.taskDate).format('YYYY-MM-DD'),
        taskStart: this.addTaskForm.value.taskStart,
        taskEnd: this.addTaskForm.value.taskEnd,
        taskDuration: this.addTaskForm.value.taskEnd,
        taskLocation: this.addTaskForm.value.taskLocation,
        assignedTo: this.addTaskForm.value.assignedTo,
        mapsUrl: this.addTaskForm.value.mapsUrl,
        taskColor: this.addTaskForm.value.taskColor,
        invitees: this.addInvitees.length > 0 ? this.addInvitees : [],
        lawFirm: localStorage.getItem(LOCAL_STORAGE.LAWFIRMNAME),
        completed: false,
        caseProgress: updateClaimList ? true : false,
        timezoneOffset: new Date().getTimezoneOffset(),
        removedInvitees: this.caseTask !== undefined ? this.removedInvitees : [],
        eventStartDateTime: eventStartDateTime.toISOString(),
        eventEndDateTime: eventEndDateTime.toISOString(),
      };
      if (taskDesc !== '' && taskDesc !== undefined && taskDesc !== null) {
        formdata.taskDescription = taskDesc.replace(/\n/g, '<br/>');
      }

      if (this.caseTask !== undefined) {
        formdata.taskId = this.caseTask._id;
      }
      const apiEndPoint =
        this.caseTask !== undefined
          ? this.api.put(this.endpoints.matterTask, formdata)
          : this.api.post(this.endpoints.matterTask, formdata);

      apiEndPoint.subscribe(
        res => {
          if (res) {
            if (res) this.addTaskForm.reset();
            this.taskModal.hide();
            this.isSubmittedAddTaskForm = false;
            this.dataService.setTasks(true);
            if (updateClaimList) {
              this.confirm.emit({
                isAdd: false,
                showWarning: false,
                eventDate: this.eventDate,
                taskForm: this.addTaskForm.value,
              });
            } else {
              this.confirm.emit({
                isAdd: true,
                showWarning: false,
                eventDate: this.eventDate,
                taskForm: this.addTaskForm.value,
              });
            }
            this.toastr.success(MESSAGE.TOASTR.MESSAGE_UPDATETASK);
            // this.taskStep = '';
          }
          this.spinner.hide();
        },
        error => {
          let errMessage = error.error || MESSAGE.RESPONSE.ERROR;
          this.spinner.hide();
          this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        },
      );
    } catch (err) {
      this.spinner.hide();
    }
  }

  updateClaimDate(claimDataToSend: any) {
    claimDataToSend.additional.filter(claim => {
      if (claim.claimTitle === claimDataToSend.claimTitle) {
        claim.date = moment(this.addTaskForm.value.taskDate).format('YYYY-MM-DD');
        claim.completed = false;
      }
      return claim;
    });
    this.api.put(this.endpoints.matterClaimStep, claimDataToSend).subscribe(
      res => {
        if (res) {
          this.addTask(true);
          this.confirm.emit({ taskForm: this.addTaskForm.value });
        }
      },
      error => {
        let errMessage = error.error || MESSAGE.RESPONSE.ERROR;
        this.spinner.hide();
        this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
      },
    );
  }
  /**
   *
   * @description Method is used to add the task.
   */
  addUpdateTask() {
    this.isSubmittedAddTaskForm = true;
    if (!this.addTaskForm.valid) {
      return false;
    }
    if (
      this.addTaskForm.get('taskEnd').value < this.addTaskForm.get('taskStart').value ||
      this.addTaskForm.get('taskEnd').value === this.addTaskForm.get('taskStart').value
    ) {
      this.toastr.error(MESSAGE.TOASTR.MESSAGE_TASK_TIME, MESSAGE.TOASTR.ERROR);
      return;
    }
    this.spinner.show();
    if (!this.taskInfo?.claimDataToSend) {
      this.addTask(false);
    } else {
      this.taskInfo.claimDataToSend.claimDate = moment(this.addTaskForm.get('taskDate').value).format('YYYY-MM-DD');
      this.updateClaimDate(this.taskInfo.claimDataToSend);
    }
  }

  setColor(ev: any) {
    this.addTaskForm.get('taskColor').setValue(ev.color.hex);
  }

  cancelEditAndAddTask() {
    this.addTaskForm.reset();
    this.confirm.emit({ isAdd: false });
  }

  onRemove(ev: any) {
    if (ev && ev.value && ev.value !== '') {
      this.removedInvitees.push(ev.value);
      this.addInvitees = this.addInvitees.filter(email => email !== ev.value);
    } else {
      this.addInvitees = this.addInvitees.filter(email => email !== ev);
      this.removedInvitees.push(ev);
    }
  }

  onEmailInput(ev: any) {
    if (ev && ev.value !== '') {
      this.addInvitees.push(ev.value);
    }
  }
}
