export enum LOCAL_STORAGE {
  MATTER_NAV_DETAILS = 'matterNavDetails',
  MATTERID = 'matterId',
  MATTERTYPE = 'matterType',
  MATTERDATA = 'matterData',
  MATTERSUBTYPE = 'matterSubType',
  MATTERDETAILS = 'matterDetails',
  USERDETAILS = 'userDetails',
  UNITTIME = 'unitTime',
  LAWFIRMNAME = 'lawFirm',
  TAXRATE = 'taxRate',
  PLAYERID='playerId'
}

