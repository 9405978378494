import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import * as MESSAGE from '../../../enum/info-messages.enum';

import { ApiService, AuthService, CommonService, DataService } from '../../../services';
@Component({
  selector: 'app-verify-member',
  templateUrl: './verify-member.component.html',
  styleUrls: ['./verify-member.component.scss'],
})
export class VerifyMemberComponent implements OnInit {
  public verifyMember: FormGroup;
  processing = false;
  userId: number;
  destroy$: Subject<any> = new Subject();
  userInfo;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private data: DataService,
  ) {}

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.params.id;
    this.initializeForm();
  }

  initializeForm() {
    this.verifyMember = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  // getUser() {
  //   this.apiService.get(`${Endpoints.getMember}/${this.userId}`).pipe(takeUntil(this.destroy$)).subscribe((response) => {
  //   });

  // }

  validateMember() {
    const params = {
      email: this.verifyMember.value?.email?.trim(),
      password: this.verifyMember.value?.password?.trim(),
      userId: this.userId,
    };
    this.apiService
      .post(Endpoints.verifyMember, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          if (!response?.error) {
            this.router.navigate([`verify-member-password/${this.userId}`]);
          } else {
            this.toastrService.error(response.message);
          }
        },
        error => {
          let errMessage = error.error?.message || MESSAGE.RESPONSE.ERROR;
          this.spinner.hide();
          this.toastrService.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        },
      );
  }

  public get getControls(): any {
    return this.verifyMember.controls;
  }
}
