import { Injectable, NgModule } from '@angular/core';
import {
  BrowserModule,
  HammerModule,
  Title,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  CalendarModule,
  DateAdapter,
  CalendarNativeDateFormatter,
  DateFormatterParams,
  CalendarDateFormatter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { ToastrModule } from 'ngx-toastr';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppInterceptor } from './interceptor/app.interceptor';
import { DefaultLayoutComponent } from './containers';
import { NgAdblockDetectModule } from 'ng-adblock-detect';
import { AppTwoDigitDecimalNumberDirective } from './directive/app-two-digit-decimal-number.directive';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import * as Hammer from 'hammerjs';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';
import { Capacitor } from '@capacitor/core';
import { AuthenticationCodeComponent } from './all-modules/component/authentication-code/authentication-code.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { ComponentModule } from './all-modules/component/component.module';

polyfill({
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
});

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const APP_CONTAINERS = [DefaultLayoutComponent];
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  resourceTimeGridPlugin,
]);

@Injectable({
  providedIn: 'root',
})
class CustomDateFormatter extends CalendarNativeDateFormatter {
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: 'narrow' }).format(date);
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    SharedModule,
    FormsModule,
    NgAdblockDetectModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    FullCalendarModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({ preventDuplicates: true, positionClass: Capacitor.getPlatform() === 'web' ? 'toast-top-right' : 'toast-bottom-center' }),
    NgxDatatableModule,
    NgMultiSelectDropDownModule,
    PdfJsViewerModule,
    DatepickerModule,
    BsDatepickerModule,
    HammerModule,
    AngularSignaturePadModule,
    ComponentModule
  ],
  declarations: [AppComponent, ...APP_CONTAINERS, AppTwoDigitDecimalNumberDirective, AuthenticationCodeComponent],
  providers: [
    Title,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },

    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }

