import { INavData } from '@coreui/angular';

export const clientNavItems: INavData[] = [
  {
    class: 'border-bottom',
    divider: true,
  },
  {
    name: 'Matters',
    url: '/matters',
    icon: 'icon-drawer',
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    badge: {
      text: '',
      variant: 'success', // Replace with the desired Bootstrap variant (info, success, warning, danger, etc.)
      class: 'custom-badge-class', // Replace with any custom class you want to apply to the badge
    },
  },
  {
    name: '',
    icon: '',
    url: 'clientname',
    class: 'child-side-bar',
  },
  {
    name: 'Briefs & Dockets',
    url: '/matters/brief-dockets',
    icon: 'icon-layers',
    class: 'child-side-bar',
  },
  // {
  //   name: 'Data & Forms',
  //   url: '/matters/data-forms',
  //   icon: 'icon-docs',
  //   class: 'child-side-bar',
  // },
  // {
  //   name: 'Documents',
  //   url: '/matters/documents',
  //   icon: 'icon-doc',
  //   class: 'child-side-bar',
  //   badge: {
  //     text: '',
  //     variant: 'success',
  //     class: 'custom-badge-class-document',
  //   },
  // },
  // {
  //   name: 'Messages',
  //   url: '/matters/chat',
  //   icon: 'fa fa-commenting-o',
  //   class: 'child-side-bar',
  //   badge: {
  //     text: '',
  //     variant: 'success',
  //     class: 'custom-badge-class-message',
  //   },
  // },
];
