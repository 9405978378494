import { Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  template: `
  <ng-container [formGroup]="formGroup">
    <input class="form-control" type="text" [formControlName]="controlName" />
    </ng-container>
  `,
  styles: [
  ]
})
export class InputComponent implements OnInit {
  @Input() formGroup: any;
  @Input() controlName: any;
  constructor() { }

  ngOnInit(): void {
  }

}
