import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationHandlerService {

  constructor() { }

  formFieldValidation(formGroupName: FormGroup, formField: string, validationType: string, controlName?: string, index?: number) {
    if (controlName !== undefined) {
      return (
        formGroupName.controls[controlName].get([formField]).hasError(validationType) &&
        (formGroupName.controls[controlName].get([formField]).dirty ||
          formGroupName.controls[controlName].get([formField]).touched)
      );
    } else {
      return (
        formGroupName.get([formField]).hasError(validationType) &&
        (formGroupName.get([formField]).dirty ||
          formGroupName.get([formField]).touched)
      );
      //   return (
      //     this.dependants.at(index).get(formField).hasError(validationType) &&
      //     (this.dependants.at(index).get(formField).dirty ||
      //       this.dependants.at(index).get(formField).touched)
      //   );
      // }

    }
  }

}
