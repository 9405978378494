import { Injectable } from '@angular/core';
import { OneSignalService } from './one-signal.service';
import { Router } from '@angular/router';
import { NotificationAppId } from '../enum/notification.enum';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebNotificationService {
  OneSignal = window['OneSignal'] || [];
  constructor(private oneSignalService: OneSignalService, private router: Router) {}

  async init() {
    if (!this.OneSignal) {
      console.error('OneSignal SDK not available.');
      return;
    }
    this.OneSignal.push([
      'init',
      {
        appId: environment.oneSignalAppId,
        autoRegister: true,
        allowLocalhostAsSecureOrigin: true,
      },
    ]);

    this.OneSignal.push(() => {
      this.OneSignal.isPushNotificationsEnabled().then(isEnabled => {
        if (!isEnabled) {
          console.log('Push notifications are not enabled yet.!');
          this.OneSignal.showSlidedownPrompt();
        }
      });
    });
  }

  async getUserAppId(): Promise<any> {
    console.log('Getting user app Id...........');
    return new Promise((resolve, reject) => {
      try {
        //   this.OneSignal.on('subscriptionChange', (isSubscribed) => {
        this.OneSignal.push(() => {
          this.OneSignal.getUserId(async userId => {
            if (userId) {
              await this.oneSignalService.setPlayerIdToLocalStorage(userId);
              resolve(userId);
            } else {
              reject(false);
            }
          });
        });
        // });
      } catch (error) {
        console.log('** ->  ~ file: web-notification.service.ts:66 ~ returnnewPromise ~ error:', error);
        reject(false);
      }
    });
  }
}
