import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchFilterPipe } from './pipe/searchPipe';
import {
  CalendarModule,
  DateAdapter,
  CalendarNativeDateFormatter,
  DateFormatterParams,
  CalendarDateFormatter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertsComponent } from './component/alerts/alerts.component';
import { AddDocketComponent } from './component/add-docket/add-docket.component';
import { AddTaskComponent } from './component/add-task/add-task.component';
import { FieldSelectionComponent } from './component/field-selection/field-selection.component';
import { InputComponent } from './component/input/input.component';
import { SelectComponent } from './component/select/select.component';
import { DateComponent } from './component/date/date.component';
import { CheckboxComponent } from './component/checkbox/checkbox.component';
import { HeaderComponent } from './component/header/header.component';
import { MatterListComponent } from './component/matter-list/matter-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LawFirmMembersComponent } from './component/law-firm-members/law-firm-members.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddPrecedentComponent } from './component/precedent/add-precedent/add-precedent.component';
import { PrecedentComponent } from './component/precedent/precedent.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorCircleModule } from 'ngx-color/circle';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AddEventComponent } from './component/add-event/add-event.component';
import { TaskDetailsComponent } from './component/task-details/task-details.component';
import { TagInputModule } from 'ngx-chips';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddPaymentComponent } from './component/add-payment/add-payment.component';
import { FileViewerComponent } from './component/file-viewer/file-viewer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { TrustReceiptsComponent } from '../all-modules/pages/matters/accounting/trust/trust-receipts/trust-receipts.component';
import { TrustDisbursementsComponent } from '../all-modules/pages/matters/accounting/trust/trust-disbursements/trust-disbursements.component';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TransferFromTrustComponent } from '../all-modules/pages/matters/accounting/fees-disbursement-payments/transfer-from-trust/transfer-from-trust.component';
import { InterTrustTransferRecordComponent } from '../all-modules/pages/matters/accounting/trust/inter-trust-transfer-record/inter-trust-transfer-record.component';
import { ClientsTrustComponent } from '../all-modules/pages/accounts/ledger/clients-trust/clients-trust.component';
import { EditTrustReciptsComponent } from './component/edit-trust-recipts/edit-trust-recipts.component';
import { VerifyMemberComponent } from './component/verify-member/verify-member.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { DndModule } from 'ngx-drag-drop';
import { PageNumberComponent } from './component/page-number/page-number.component';
import { SignaturePadModalComponent } from './component/signature-pad-modal/signature-pad-modal.component';
import { SafeHtmlPipe } from 'app/shared/pipe/sanitizeUrlPipe';
import { FileUploadDirective } from 'app/directive/file-upload.directive';

@Injectable({
  providedIn: 'root',
})
class CustomDateFormatter extends CalendarNativeDateFormatter {
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: 'narrow' }).format(date);
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule,
    TabsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    NgxPaginationModule,
    ColorSketchModule,
    ColorCircleModule,
    PopoverModule,
    NgMultiSelectDropDownModule,
    TagInputModule,
    PdfJsViewerModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule,
    DndModule,
  ],
  declarations: [
    SearchFilterPipe,
    AlertsComponent,
    AddDocketComponent,
    AddTaskComponent,
    FieldSelectionComponent,
    InputComponent,
    SelectComponent,
    DateComponent,
    CheckboxComponent,
    HeaderComponent,
    MatterListComponent,
    LawFirmMembersComponent,
    PrecedentComponent,
    AddPrecedentComponent,
    AddEventComponent,
    TaskDetailsComponent,
    AddPaymentComponent,
    FileViewerComponent,
    TrustReceiptsComponent,
    TrustDisbursementsComponent,
    TransferFromTrustComponent,
    InterTrustTransferRecordComponent,
    ClientsTrustComponent,
    EditTrustReciptsComponent,
    VerifyMemberComponent,
    ResetPasswordComponent,
    PageNumberComponent,
    SignaturePadModalComponent,
    SafeHtmlPipe,
    FileUploadDirective,
  ],
  exports: [
    SafeHtmlPipe,
    SearchFilterPipe,
    AlertsComponent,
    AddDocketComponent,
    AddTaskComponent,
    FieldSelectionComponent,
    InputComponent,
    SelectComponent,
    DateComponent,
    CheckboxComponent,
    HeaderComponent,
    MatterListComponent,
    LawFirmMembersComponent,
    PrecedentComponent,
    AddPrecedentComponent,
    AddEventComponent,
    TaskDetailsComponent,
    AddPaymentComponent,
    FileViewerComponent,
    TrustReceiptsComponent,
    TrustDisbursementsComponent,
    TransferFromTrustComponent,
    InterTrustTransferRecordComponent,
    ClientsTrustComponent,
    ResetPasswordComponent,
    PageNumberComponent,
    SignaturePadModalComponent,
    FileUploadDirective,
  ],
  providers: [{ provide: CalendarDateFormatter, useClass: CustomDateFormatter }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
