// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // base_url: 'https://alexur-dev.herokuapp.com/api/',
  base_url: 'https://alexur-dev-backend.azurewebsites.net/api/', // Use this
  // base_url: 'http://localhost:3001/api/',
  // base_url: 'http://192.168.1.252:3001/api/',
  oneSignalAppId: `70f061fa-983b-46cc-92a8-754f4fe0f527`,
  // socket_url: 'http://192.168.1.252:3001',
  socket_url: 'https://alexur-dev-backend.azurewebsites.net',
  // pdfGeneratorUrl: 'http://localhost:3456/',
  // new
  // pdfGeneratorUrl: 'http://192.168.1.131:3456/',
  pdfGeneratorUrl: 'https://pdfgen.alexur.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
