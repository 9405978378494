import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../services/api.service';
import { PaymentMethods } from '../../config/payment-methods';
import { Endpoints } from '../../../config';

@Component({
  selector: 'app-edit-trust-recipts',
  templateUrl: './edit-trust-recipts.component.html',
  styleUrls: ['./edit-trust-recipts.component.scss'],
})
export class EditTrustReciptsComponent implements OnInit, OnChanges {
  @ViewChild('editModal') public editModal: ModalDirective;
  @Input() receipt: any;
  @Output() confirm = new EventEmitter();
  @Input() selectedInvoice: any;
  @Input() type: string;
  @Input() matterDetails: any;
  @Input() totalBalance: number;
  @Input() addDisbursement = false;
  methods = [];
  receipts: any;
  labels = {
    Cheque: 'Cheque No',
    Cash: 'Cash Memo No',
    'Bank Transfer': 'Transaction ID',
    'Electronic Money Transfer': 'Transaction ID',
    'Credit card': 'Transaction ID',
  };
  editTrustReceiptForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.initializeTrustForm();
    this.methods = PaymentMethods.filter(method => method != 'Deduct from Trust');
  }

  initializeTrustForm() {
    this.editTrustReceiptForm = this.fb.group({
      paymentMethod: ['', Validators.required],
      refId: ['', Validators.required],
      lastFourDigits: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{4}$')],
      amount: ['', [Validators.required, Validators.min(1)]],
      purposeOfFunds: [''],
      creditType: ['trust receipts', Validators.required],
    });
    this.editTrustReceiptForm.patchValue({
      paymentMethod: this.receipt.paymentMethod,
      refId: this.receipt.refId,
      lastFourDigits: this.receipt.lastFourDigits,
      amount: this.receipt.amount.$numberDecimal,
      purposeOfFunds: this.receipt.purposeOfFunds,
      creditType: this.receipt.creditType,
    });
  }

  get getFormControls() {
    return this.editTrustReceiptForm.controls;
  }

  ngOnChanges() {
    setTimeout(() => {
      this.editModal.show();
    }, 100);
  }

  onCancel() {
    this.confirm.emit({ state: false, type: 'edit modal' });
  }

  enableControls() {
    this.editTrustReceiptForm?.get('trustBalance')?.enable();
    this.editTrustReceiptForm?.get('amount')?.enable();
  }

  savePaymentInfo() {
    this.editTrustReceiptForm?.get('paymentMethod')?.enable();
    const params: any = {
      paymentMethod: this.editTrustReceiptForm.value.paymentMethod,
      refId: this.editTrustReceiptForm.value.refId,
      lastFourDigits: this.editTrustReceiptForm.value.lastFourDigits,
      amount: +this.editTrustReceiptForm.value.amount,
      purposeOfFunds: this.editTrustReceiptForm.value.purposeOfFunds,
      comments: this.editTrustReceiptForm?.value?.comment,
    };

    if (this.type === 'trust') {
      if (this.editTrustReceiptForm.value?.purposeOfFunds.length === 0) {
        if (this.matterDetails?.matterType.toLowerCase() === 'immigration') {
          params.purposeOfFunds = `${this.matterDetails?.matterType} - ${this.matterDetails.matterSubType}`;
        } else {
          params.purposeOfFunds = `${this.matterDetails.matterType}`;
        }
      } else {
        params.purposeOfFunds = this.editTrustReceiptForm.value?.purposeOfFunds;
      }
      params.trustBalance = +this.editTrustReceiptForm.value.amount + this.totalBalance;
      params.creditType = this.editTrustReceiptForm.value.creditType;
    } else {
      params.trustBalance = +this.editTrustReceiptForm.value.trustBalance;
    }

    // return

    const api: any =
      this.type === 'invoice' && !this.addDisbursement
        ? this.apiService.put(Endpoints.addPayment, params)
        : this.apiService.put(Endpoints.trust, params);

    api.subscribe(
      (payment: any) => {
        this.spinner.hide();
        this.toastr.success(payment.message);
        this.confirm.emit({ isDone: true });
      },
      err => {
        this.confirm.emit({ isDone: false });
        this.spinner.hide();
      },
    );
  }

  // addRefund() {
  //   const params: any = {
  //     amount: +this.editTrustReceiptForm.value.amount,
  //     lastFourDigits: this.editTrustReceiptForm.value.lastFourDigits,
  //     paymentMethod: this.editTrustReceiptForm.value.paymentMethod,
  //     invoiceId: this.selectedInvoice?._id,
  //     comments: this.editTrustReceiptForm?.value?.comment,
  //     matterId: this.matterDetails._id,
  //     clientId: this.matterDetails.clientId,
  //     refId: this.editTrustReceiptForm.value.refId,
  //     transferredBy: this.matterDetails.clientId,
  //     invoiceAmount: this.selectedInvoice?.total?.$numberDecimal
  //   }

  //   const apiEndPoints: any = this.type === 'refund' ? this.apiService.put(Endpoints.refundAmount, params) : this.apiService.post(Endpoints.refundAmount, params);
  //   apiEndPoints.subscribe((res: any) => {
  //     this.spinner.hide();
  //     this.toastr.success(res.message);
  //     this.confirm.emit({ isDone: true });
  //   }, err => {
  //     this.confirm.emit({ isDone: false });
  //     this.spinner.hide();
  //   })
  // }

  updateReceipt() {
    // this.spinner.show();
    const params: any = {
      paymentMethod: this.editTrustReceiptForm.value.paymentMethod,
      refId: this.editTrustReceiptForm.value.refId,
      amount: +this.editTrustReceiptForm.value.amount,
      receipt: this.receipt,
    };
    const apiEndPoints: any = this.apiService.put(Endpoints.updateReceipt, params).subscribe(
      (res: any) => {
        this.toastr.success(res.message);
        this.confirm.emit({ state: true, type: 'edit modal' });
      },
      err => {
        console.log(
          'TCL ->  ~ file: trust-receipts.component.ts ~ line 98 ~ TrustReceiptsComponent ~ this.apiService.deleteWithParams ~ err',
          err,
        );
        this.confirm.emit({ state: true, type: 'edit modal' });
        this.toastr.error(err?.message);
      },
    );
    // apiEndPoints.subscribe((res: any) => {
    //   this.spinner.hide();
    //   this.toastr.success(res.message);
    //   this.confirm.emit({ isDone: true });
    // }, err => {
    //   this.confirm.emit({ isDone: false });
    //   this.spinner.hide();
    // })
  }

  validateNumber(ev: any, controlName: string) {
    if (ev.target.value < 0) {
      this.editTrustReceiptForm.get(controlName).setValue(0);
    }
    // this.errMessage = `Transfer amount should be less or equal to trust balance`;
  }

  getList() {
    this.spinner.show();
    this.apiService.get(Endpoints.interTrustTransfer).subscribe(
      (data: any) => {
        this.receipts = data;
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      },
    );
  }
}
