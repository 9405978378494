import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService, CommonService } from './services';
import { Title } from '@angular/platform-browser';
import { OneSignalService } from './services/one-signal.service';
import { App as CapacitorApp } from '@capacitor/app';
import { Toast } from '@capacitor/toast';
import { SplashScreen } from '@capacitor/splash-screen';
import { Location } from '@angular/common';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: ` <!-- <app-alert
  [title]="'Instruction'"
  [alertMessage]="'Please create new task'"
  [confirmButton]="'Ok'"
  (confirm)="onConfirm($event)"
  *ngIf="showAlert"
></app-alert> -->
    <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  backButtonPressed: boolean = false;
  showAlert: boolean = false;
  lawfirmName: string;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private authService: AuthService,
    private titleService: Title,
    private oneSignalService: OneSignalService,
    private location: Location,
  ) {}

  // @HostListener('window: popstate', ['$event'])
  // onPopState(event: Event): void {
  //   event.preventDefault();
  //   // console.log("BACK PRESSED");
  //   this.backButtonPressed = true;
  // }

  // @HostListener('window:unload', ['$event'])
  // unloadHandler(event) {
  //   localStorage.setItem('closedTab', 'closed alexur tab');
  //   console.log('Closed alexur tab');
  // }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event) {
  //   localStorage.setItem('closedBrowser', 'closed browser');

  //   console.log('Browser closed');

  // }

  async ngOnInit() {
    await SplashScreen.show({
      showDuration: 2000,
      autoHide: true,
    });

    document.addEventListener(
      'deviceready',
      () => {
        SplashScreen.hide();
        this.oneSignalService.initOneSignal();
      },
      false,
    );

    this.commonService.getLawfirm();
    this.lawfirmName = this.commonService.getLawfirmName();
    if (this.lawfirmName) {
      const title = `${this.lawfirmName} - Alexur`;
      if (title.includes('null')) {
        this.titleService.setTitle('Alexur - CRM');
      } else {
        this.titleService.setTitle(title);
      }
    }

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      } else {
        if (evt.url) {
          if (evt.url.includes('matters/immigration') || evt.url.includes('matters/family')) {
            const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
            const formName = title.split('-').pop();
            this.titleService.setTitle(formName);
          } else {
            if (this.lawfirmName) {
              const title = `${this.lawfirmName} - Alexur`;
              if (title.includes('null')) {
                this.titleService.setTitle('Alexur - CRM');
              } else {
                this.titleService.setTitle(title);
              }
            }
          }
        }
      }
    });

    let userLoggedIn = this.authService.checkLogin();
    if (userLoggedIn) {
      this.commonService.getLawfirm();
    }

    let cnt = 0;

    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      Toast.show({
        text: 'Press again to exit',
      });
      cnt++;
      setTimeout(function () {
        if (cnt === 2) {
          CapacitorApp.exitApp();
        }
      }, 600);

      setTimeout(function () {
        cnt = 0;
      }, 700);
    });

    // CapacitorApp.addListener('backButton', (data) => {
    //   if (data.canGoBack) {
    //     // window.history.back();
    //     this.location.back();
    //   } else {
    //     CapacitorApp.exitApp();
    //   }
    // });
  }

  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  onConfirm(ev: any) {
    this.showAlert = false;
  }

  setDocTitle(title: string) {
    this.titleService.setTitle(title);
  }
}
