import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DataService, ApiService, CommonService, AlertService, AuthService, BriefDocketService } from '../../../services';

import { Endpoints } from '../../../config';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit, OnChanges {
  @ViewChild('viewFile') public viewFile: ModalDirective;
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  @ViewChild('pdfViewerAutoLoad', { static: false }) pdfViewerAutoLoad;

  @Input() matterDetails: any;
  @Input() disbursementData: any;
  @Output() confirm = new EventEmitter();
  endPoints = Endpoints;
  setBgColor: boolean = false;
  isImg: boolean = false;
  isShowPdf: boolean = false;
  count: number = 0;
  receipts: any;
  noPreviewAvailable: boolean = false;
  i = 0;
  blobRes: any = '';
  fileName = '';
  fileData = [];

  constructor(private dataService: DataService, private apiService: ApiService, private spinner: NgxSpinnerService, private toastr: ToastrService, private commonService: CommonService, private alert: AlertService, private auth: AuthService, private router: Router, private briefDocketService: BriefDocketService,
    private sanitize: DomSanitizer,) { }

  ngOnInit(): void {
    const id = this.disbursementData?.receiptIds[this.count];
    this.fileData = this.disbursementData?.receiptRec?.length > 0 ? this.disbursementData?.receiptRec : []
    this.attachReceipt(id);
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.viewFile.show();
    }, 100);
  }

  attachReceipt(receiptId?: any) {
    this.spinner.show();

    this.commonService.getFile({ receiptId }, `${environment.base_url}${this.endPoints.getReceiptsWith}`).subscribe((receipts: any) => {
      this.viewFileContent(receipts, this.count);
      this.spinner.hide();

    }, err => {
      this.spinner.hide();
    });

  }

  viewFileContent(res: any, index?: number) {
    this.noPreviewAvailable = false;
    this.blobRes = '';

    if (index) {
      this.count = index;
    }


    const blob = new Blob([res], { type: res.type });
    if (res.type == 'undefined') {
      this.setBgColor = true;
    }
    if (res.type === 'application/pdf') {
      this.isShowPdf = true;
      this.blobRes = blob;
      setTimeout(() => {
        if (this.blobRes !== '') {
          this.pdfViewerAutoLoad.pdfSrc = this.blobRes; // pdfSrc can be Blob or Uint8Array
          this.pdfViewerAutoLoad.refresh();
        } else {
          this.noPreviewAvailable = true;
        }
        // this.blobUrl = blob;
        this.spinner.hide();
      }, 500);
    } else if (res.type.includes('image')) {
      this.isShowPdf = false;
      this.isImg = true;
      this.blobRes = this.sanitize.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));

    }
    else {
      this.fileName = this.disbursementData?.receiptRec[0]?.receiptPath?.split('-')?.pop();
      this.isImg = false;
      this.isShowPdf = false;
      this.setBgColor = true;
      this.blobRes = this.sanitize.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
      this.noPreviewAvailable = true;
    }
    this.spinner.hide();

  }

  getDocs(type: string) {
    this.fileName = '';
    if (type === 'next') {
      this.count = this.count + 1;
    } else if (type === 'previous') {
      this.count = this.count > 0 ? this.count - 1 : 0;
    }


    const disbursement = this.disbursementData?.receiptIds?.length > 0 ? this.disbursementData?.receiptIds : [];
    const filesDataLength = disbursement?.length;
    if (this.count <= filesDataLength) {
      const fileInputData = this.fileData[this.count];
      if (!fileInputData?.receiptPath.includes('pdf') &&
        !fileInputData?.receiptPath.includes('jpeg') &&
        !fileInputData?.receiptPath.includes('jpg') &&
        !fileInputData?.receiptPath.includes('png') &&
        !fileInputData?.receiptPath.includes('txt')) {
        this.noPreviewAvailable = true;
      } else {
        this.noPreviewAvailable = false;
        this.attachReceipt(disbursement[this.count]);
      }
    }
  }

  close() {
    this.confirm.emit({ isAdd: false });
  }


}
